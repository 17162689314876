import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import '../styles/index.scss';

function PageNotFound() {
  return (
    <Layout>
      <div className="error-container">
        <div>
          <h2>Ouch! The site was not able</h2>
          <Link to="/">
            <p>Go to homepage</p>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default PageNotFound;
